import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {selectCities} from "../../core/coreSlice";
import {selectAccount} from "../../account/accountSlice";
import React, {useState} from "react";
import {ErrorFormatter} from "../../common/ErrorFormatter";
import {LogicError} from "../../common/LogicError";
import {Box, Chip, IconButton, Menu, MenuItem, Paper, Typography} from "@mui/material";
import {ContestFormatter} from "../ContestFormatter";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {ContestUtils} from "../ContestUtils";
import {LoadingButton} from "@mui/lab";
import {ConfirmDialog} from "../../common/ConfirmDialog";
import {PinspinClient} from "../../../api/PinspinClient";
import {CompetitionStatusClientDto, ContestClientDto, ContestStatusClientDto} from "../../../api/NswagClient";
import {TagComponent, TitleComponent} from "../../common/TitleComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreVertIcon from "@mui/icons-material/MoreVert";

export function ContestDescription(props: {
    contest: ContestClientDto,
    onChanged: (contest: ContestClientDto) => void
}) {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const cities = useSelector(selectCities);
    const account = useSelector(selectAccount);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    const [publishing, setPublishing] = useState(false);
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
    const [canceling, setCanceling] = useState(false);
    const [finishing, setFinishing] = useState(false);
    const [joining, setJoining] = useState(false);
    const [leaving, setLeaving] = useState(false);

    const contest = props.contest;

    const handleOpenContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigate(`/contests/${contest?.contestId}/edit`);
    };

    const handleStart = () => {
        if (contest!.participants!.length < 2) {
            enqueueSnackbar(ErrorFormatter.format(LogicError.new('Для запуска турнира необходимо минимум два участника')), { variant: "error" });
            return;
        }
        navigate(`/contests/${contest?.contestId}/start`);
    };

    const handlePublish = async () => {
        try {
            if (!contest) {
                return;
            }
            setPublishing(true);
            const contestService = new PinspinClient();
            await contestService.publishContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Турнир опубликован', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setPublishing(false);
        }
    };

    const handleCancel = async () => {
        try {
            if (!contest) {
                return;
            }
            setCanceling(true);
            const contestService = new PinspinClient();
            await contestService.cancelContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            setIsCancelDialogOpen(false);
            enqueueSnackbar('Турнир удален', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setCanceling(false);
        }
    };

    const handleFinish = async () => {
        try {
            if (!contest) {
                return;
            }
            setFinishing(true);
            const contestService = new PinspinClient();
            await contestService.finishContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Турнир завершен', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setFinishing(false);
        }
    };

    const handleJoin = async () => {
        try {
            if (!contest) {
                return;
            }
            setJoining(true);
            const contestService = new PinspinClient();
            await contestService.joinContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Заявка отправлена', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setJoining(false);
        }
    };

    const handleLeave = async () => {
        try {
            if (!contest) {
                return;
            }
            setLeaving(true);
            const contestService = new PinspinClient();
            await contestService.leaveContest(contest.contestId!);
            props.onChanged(await contestService.getContest(contest.contestId!));
            enqueueSnackbar('Заявка отозвана', { variant: "success" });
        } catch (e) {
            enqueueSnackbar(ErrorFormatter.format(e), { variant: "error" });
        } finally {
            setLeaving(false);
        }
    };

    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));
    const isOrganizer = account?.userId === contest.organizer?.userId;

    return (
        <>
            <Paper elevation={0} sx={{ p: 2 }}>

                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: 1 }}>
                        <TitleComponent>{contest.title}</TitleComponent>
                        <TagComponent color='primary.contrastText'
                                      backgroundColor='secondary.main'>
                            {ContestFormatter.formatStatus(contest.status!).toLowerCase()}
                        </TagComponent>
                    </Box>

                    <Typography sx={{ fontSize: 12, mt: 1 }}
                                color="text.secondary"
                                noWrap>
                        Локация
                    </Typography>
                    <Typography>{cityNames.get(contest.locationId) ?? ''}, {contest.address}</Typography>

                    <Typography sx={{ fontSize: 12, mt: 1 }}
                                color="text.secondary"
                                noWrap>
                        Время
                    </Typography>
                    <Typography>{format(contest.dueDate!, 'dd MMM HH:mm', { locale: ru })}</Typography>

                    {
                        contest.description!.length <= 50 &&
                        <Typography sx={{ mt: 1 }}>{contest.description}</Typography>
                    }
                    {
                        contest.description!.length > 50 &&
                        <Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                gap: 1
                            }}>
                                <Typography sx={{ mt: 1 }}
                                            noWrap={!descriptionExpanded}>{contest.description}</Typography>
                                <IconButton onClick={() => setDescriptionExpanded(!descriptionExpanded)}>
                                    {
                                        !descriptionExpanded && <ExpandMoreIcon/>
                                    }
                                    {
                                        descriptionExpanded && <ExpandLessIcon/>
                                    }
                                </IconButton>
                            </Box>
                        </Box>
                    }
                </Box>

                <Box sx={{ mt: 1 }}>
                    {
                        ContestUtils.isPendingJoin(account, contest) &&
                        <Chip label='Ваша заявка на участие на рассмотрении' color='primary'/>
                    }
                    {
                        ContestUtils.isRejected(account, contest) &&
                        <Chip label=' Ваша заявка на участие отклонена' color='error'/>
                    }
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1 }}>

                    {
                        ContestUtils.canLeave(account, contest) &&
                        <LoadingButton size="small" onClick={handleLeave}
                                       loading={leaving}>Не участвовать</LoadingButton>
                    }

                    {
                        ContestUtils.canJoin(account, contest) &&
                        <LoadingButton size="small" onClick={handleJoin}
                                       loading={joining}>Участвовать</LoadingButton>
                    }

                    {
                        isOrganizer &&
                        <>
                            {
                                contest.status === ContestStatusClientDto.Draft &&
                                <LoadingButton size="small" onClick={handlePublish}
                                               loading={publishing}>Опубликовать</LoadingButton>
                            }

                            {
                                contest.status === ContestStatusClientDto.Published &&
                                <LoadingButton size="small" onClick={handleStart}>Запустить</LoadingButton>
                            }

                            {
                                contest.status === ContestStatusClientDto.Started &&
                                <LoadingButton size="small"
                                               disabled={contest.competition?.status !== CompetitionStatusClientDto.Finished}
                                               onClick={handleFinish}
                                               loading={canceling}>Завершить</LoadingButton>
                            }

                            <Box sx={{ flexGrow: 1 }}></Box>

                            <IconButton edge="end"
                                        onClick={e => {
                                            handleOpenContextMenu(e)
                                        }}>
                                <MoreVertIcon/>
                            </IconButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseContextMenu}
                            >
                                {
                                    ContestUtils.canEdit(account, contest) &&
                                    <MenuItem onClick={() => {
                                        handleCloseContextMenu();
                                        handleEdit();
                                    }}>
                                        Редактировать
                                    </MenuItem>
                                }

                                <MenuItem onClick={() => {
                                    handleCloseContextMenu();
                                    setIsCancelDialogOpen(true);
                                }}>
                                    Удалить
                                </MenuItem>
                            </Menu>

                        </>
                    }

                </Box>

            </Paper>

            <ConfirmDialog open={isCancelDialogOpen} title={'Турнир будет удален, продолжить?'}
                           onOk={handleCancel}
                           onClose={() => setIsCancelDialogOpen(false)}/>
        </>
    );
}
