import {
    AccountRoleClientDto,
    ContestFormatTypeClientDto,
    ContestOrganizerClientDto,
    ContestParticipantClientDto,
    ContestParticipantStatusClientDto,
    ContestStatusClientDto
} from "../../api/NswagClient";

export class ContestFormatter {

    static formatOrganizerName(organizer: ContestOrganizerClientDto) {

        if (organizer.role === AccountRoleClientDto.Player && organizer.firstName && organizer.lastName) {
            return `${organizer.firstName} ${organizer.lastName}`;
        }

        if (organizer.role === AccountRoleClientDto.Coach && organizer.firstName && organizer.lastName) {
            return `${organizer.firstName} ${organizer.lastName}`;
        }

        if (organizer.role === AccountRoleClientDto.Gym && organizer.name) {
            return `${organizer.name}`;
        }

        if (organizer.role === AccountRoleClientDto.Store && organizer.name) {
            return `${organizer.name}`;
        }

        return organizer.username;
    }

    static formatOrganizerLink(organizer: ContestOrganizerClientDto) {
        if (organizer.role === AccountRoleClientDto.Player) {
            return `/players/${organizer.username}`;
        }

        if (organizer.role === AccountRoleClientDto.Coach) {
            return `/coaches/${organizer.username}`;
        }

        if (organizer.role === AccountRoleClientDto.Gym) {
            return `/gyms/${organizer.username}`;
        }

        if (organizer.role === AccountRoleClientDto.Store) {
            return `/stores/${organizer.username}`;
        }

        return '';
    }

    static formatStatus(status: ContestStatusClientDto) {
        switch (status) {
            case ContestStatusClientDto.Draft:
                return 'Черновик';
            case ContestStatusClientDto.Published:
                return 'Опубликован';
            case ContestStatusClientDto.Canceled:
                return 'Удален';
            case ContestStatusClientDto.Started:
                return 'Начат';
            case ContestStatusClientDto.Finished:
                return 'Завершен';
            default:
                return '';
        }
    }

    static formatParticipantName(participant?: ContestParticipantClientDto) {

        if (!participant) {
            return '';
        }

        if (participant.firstName && participant.lastName) {
            return `${participant.lastName} ${participant.firstName}`;
        }

        if (participant.username) {
            return participant.username;
        }

        return 'Unknown';
    }

    static formatParticipantNameShort(participant?: ContestParticipantClientDto) {

        if (!participant) {
            return '';
        }

        if (participant.firstName && participant.lastName) {
            return `${participant.lastName} ${participant.firstName.at(0)}`;
        }

        if (participant.username) {
            return participant.username;
        }

        return 'Unknown';
    }

    static formatParticipantRating(participant: ContestParticipantClientDto) {
        if (participant.rating) {
            return participant.rating.toFixed(2);
        }
        return 'без рейтинга';
    }

    static formatParticipantStatus(status: ContestParticipantStatusClientDto) {
        switch (status) {
            case ContestParticipantStatusClientDto.New:
                return 'Заявка';
            case ContestParticipantStatusClientDto.Accepted:
                return 'Одобрена';
            case ContestParticipantStatusClientDto.Rejected:
                return 'Отклонена'
            default:
                return '';
        }
    }

    static formatContestFormatType(formatType: ContestFormatTypeClientDto) {
        switch (formatType) {
            case ContestFormatTypeClientDto.RoundRobin:
                return 'Круговая';
            case ContestFormatTypeClientDto.Swiss:
                return 'Швейцарская';
            case ContestFormatTypeClientDto.Playoff:
                return 'Плейофф';
            case ContestFormatTypeClientDto.RoundRobinAndSwiss:
                return 'Круговая + Швейцарская';
            case ContestFormatTypeClientDto.RoundRobinAndPlayoff:
                return 'Круговая + Плейофф';
            default:
                return '';
        }
    }
}