import {ContestClientDto} from "../../../api/NswagClient";
import {
    Avatar,
    Box,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import {ContestFormatter} from "../ContestFormatter";
import React from "react";
import {useSelector} from "react-redux";
import {selectCities} from "../../core/coreSlice";

export function CompetitionStandingsView(props: {
    contest: ContestClientDto
}) {

    const locations = useSelector(selectCities);
    const participantsMap = new Map(props.contest.participants!.map(x => [x.participantId!, x]));

    const locationNames = new Map((locations ?? []).map(x => [x.cityId!, x.name!]));

    return (
        <Paper elevation={0} sx={{ mt: 1 }}>
            <Box>

                <Box sx={{ pl: 2, pr: 2, pt: 2 }}>
                    <Typography sx={{ fontSize: 12 }}
                                color="text.secondary"
                                noWrap>
                        Итоги
                    </Typography>
                </Box>

                <TableContainer>
                    <Table size="small" sx={{ mt: 1, mb: 1 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Участник</TableCell>
                                <TableCell>Город</TableCell>
                                <TableCell>Место</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.contest.competition?.standings?.map(standing => {
                                    const participant = participantsMap.get(standing.competitorId!)!;
                                    return (
                                        <TableRow key={standing.competitorId}>

                                            <TableCell>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }}>
                                                    <Avatar sx={{ width: 24, height: 24 }} src={participant.avatar}/>
                                                    <Typography sx={{ fontSize: 14 }} noWrap>
                                                        {ContestFormatter.formatParticipantName(participant)}
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                {participant.locationId ? locationNames.get(participant.locationId) : ''}
                                            </TableCell>

                                            <TableCell align='right'>
                                                {standing.position}
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>
        </Paper>
    )
        ;
}