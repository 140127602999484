import React, {useEffect, useState} from "react";
import {
    Alert, Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ErrorFormatter} from "../common/ErrorFormatter";
import {LoadingButton} from "@mui/lab";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import {useAppDispatch} from "../../app/hooks";
import {login} from "../account/accountSlice";
import {AvatarUploader} from "./AvatarUploader";
import {PlayerFormatter} from "../player/PlayerFormatter";
import {PinspinClient} from "../../api/PinspinClient";
import {
    CityClientDto,
    PlayerDominantHandClientDto,
    PlayerGripClientDto,
    PlayerPlayingStyleClientDto, PlayerProfileClientDto, UpdatePlayerProfileClientDto
} from "../../api/NswagClient";

export function PlayerProfileEdit(props: { profile: PlayerProfileClientDto }) {

    const dispatcher = useAppDispatch();
    const profile = props.profile;
    const { enqueueSnackbar } = useSnackbar();
    const [cities, setCities] = useState<CityClientDto[]>();
    const [error, setError] = useState<any>();

    useEffect(() => {
        (async () => {
            try {
                const citiesResult = await new PinspinClient().getCities(0, 1000);
                setCities(citiesResult.items!);
            } catch (e) {
                setError(e);
            }
        })()
    }, []);

    const formik = useFormik<ProfileEditForm>({
        initialValues: {
            firstName: profile.firstName!,
            lastName: profile.lastName!,
            cityId: profile.cityId!,
            dominantHand: profile.dominantHand!,
            grip: profile.grip!,
            playingStyle: profile.playingStyle!
        },
        validate: values => {
            const errors = {};
            if (values.firstName.trim().length === 0) {
                Object.assign(errors, { firstName: 'Не должно быть пустым' });
            }
            if (values.lastName.trim().length === 0) {
                Object.assign(errors, { lastName: 'Не должно быть пустым' });
            }
            if (!values.cityId || values.cityId <= 0) {
                Object.assign(errors, { cityId: 'Необходимо выбрать' });
            }
            if (values.grip === PlayerGripClientDto.None) {
                Object.assign(errors, { grip: 'Необходимо выбрать' });
            }
            if (values.playingStyle === PlayerPlayingStyleClientDto.None) {
                Object.assign(errors, { playingStyle: 'Необходимо выбрать' });
            }
            if (values.dominantHand === PlayerDominantHandClientDto.None) {
                Object.assign(errors, { dominantHand: 'Необходимо выбрать' });
            }
            return errors;
        },
        onSubmit: async (values, fmk) => {
            try {
                setError(null);
                await new PinspinClient().updatePlayerProfile(profile.playerId!, new UpdatePlayerProfileClientDto({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    cityId: values.cityId,
                    dominantHand: values.dominantHand,
                    grip: values.grip,
                    playingStyle: values.playingStyle
                }));
                fmk.resetForm({ values: values });
                dispatcher(login());
                enqueueSnackbar('Профиль обновлен', { variant: "success" });
            } catch (e) {
                setError(e);
            }
        }
    });

    const handleUploadFinished = () => {
        dispatcher(login());
        enqueueSnackbar('Аватар обновлен', { variant: "success" });
    };

    const handleUploadError = (error: string) => {
        enqueueSnackbar(error, { variant: "error" });
    };

    const dominantHands = [PlayerDominantHandClientDto.None, PlayerDominantHandClientDto.Right, PlayerDominantHandClientDto.Left];
    const playingStyles = [PlayerPlayingStyleClientDto.None, PlayerPlayingStyleClientDto.Attack, PlayerPlayingStyleClientDto.Defence];
    const grips = [PlayerGripClientDto.None, PlayerGripClientDto.Shakehand, PlayerGripClientDto.Penhold];
    const cityNames = new Map((cities ?? []).map(x => [x.cityId, x.name]));
    const cityIds = (cities ?? []).sort((a: CityClientDto, b: CityClientDto) => a.name! < b.name! ? -1 : 1).map(x => x.cityId);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }}>

            <Typography component="h1" variant="h5">
                Редактирование профиля
            </Typography>

            <AvatarUploader
                avatar={profile.avatar!}
                url={`/api/player/${profile.playerId}/avatar`}
                onFinish={handleUploadFinished}
                onError={handleUploadError}
            />

            <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 2 }}>

                {
                    error &&
                    <Alert sx={{ mb: 2 }} severity='error'>
                        {ErrorFormatter.format(error)}
                    </Alert>
                }

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Имя"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Фамилия"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />

                <Autocomplete
                    sx={{ mt: 2 }}
                    fullWidth
                    id='cityId'
                    options={cityIds}
                    getOptionLabel={x => cityNames.get(x) ?? '-'}
                    noOptionsText='Ничего не найдено'
                    value={formik.values.cityId || null}
                    onChange={(e, v) => formik.setFieldValue('cityId', v)}
                    renderInput={(params) => <TextField {...params} name="cityId" label='Город'
                                                        error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                                                        helperText={formik.touched.cityId && formik.errors.cityId}/>}
                />

                <FormControl fullWidth sx={{ mt: 3 }} required>
                    <InputLabel id="dominantHand">Игровая рука</InputLabel>
                    <Select
                        labelId="dominantHand"
                        label='Игровая рука'
                        name='dominantHand'
                        value={formik.values.dominantHand}
                        onChange={formik.handleChange}
                    >
                        {
                            dominantHands.map(x => <MenuItem key={x}
                                                             value={x}>{PlayerFormatter.formatDominantHand(x)}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText error={formik.touched.dominantHand && Boolean(formik.errors.dominantHand)}>
                        {formik.touched.dominantHand && formik.errors.dominantHand}
                    </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }} required>
                    <InputLabel id="playingStyle">Стиль игры</InputLabel>
                    <Select
                        labelId="playingStyle"
                        label='Стиль игры'
                        name='playingStyle'
                        value={formik.values.playingStyle}
                        onChange={formik.handleChange}
                    >
                        {
                            playingStyles.map(x => <MenuItem key={x}
                                                             value={x}>{PlayerFormatter.formatPlayingStyle(x)}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText error={formik.touched.playingStyle && Boolean(formik.errors.playingStyle)}>
                        {formik.touched.playingStyle && formik.errors.playingStyle}
                    </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }} required>
                    <InputLabel id="grip">Хват ракетки</InputLabel>
                    <Select
                        labelId="grip"
                        label='Хват ракетки'
                        name='grip'
                        value={formik.values.grip}
                        onChange={formik.handleChange}
                    >
                        {
                            grips.map(x => <MenuItem key={x}
                                                     value={x}>{PlayerFormatter.formatGrip(x)}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText error={formik.touched.grip && Boolean(formik.errors.grip)}>
                        {formik.touched.grip && formik.errors.grip}
                    </FormHelperText>
                </FormControl>

                <LoadingButton
                    sx={{ mt: 3, mb: 2 }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={formik.isSubmitting}
                    disabled={!formik.dirty}
                >
                    Сохранить
                </LoadingButton>
            </Box>
        </Box>
    );
}

interface ProfileEditForm {
    firstName: string;
    lastName: string;
    cityId: number;
    grip: PlayerGripClientDto;
    dominantHand: PlayerDominantHandClientDto;
    playingStyle: PlayerPlayingStyleClientDto;
}